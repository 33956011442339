
import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import HowItWorksSection from '../components/HowItWorksSection';
import Footer from "../components/Footer"
import ServiceLevels from '../components/ServiceLevels';
import WorkProcess from '../components/WorkProcess';
import {Row, Col,Image, Container} from "react-bootstrap"

const LandingPage = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <br /><br />
    <WorkProcess />
      <HowItWorksSection />
      <Container fluid style={{ padding: '80px 30px', minHeight: '100vh' }}>
      <Row className="justify-content-center text-center mb-5">
        <Col lg={10} md={12}>
          <h1 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '20px', color: '#333' }}>
            AI-Powered Brand Health Insights
          </h1>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#6c757d', margin: '0 auto' }}>
            At BrandInsights, we redefine brand health assessments by leveraging AI-powered audience interviews and in-depth data analysis.
            Our platform transforms qualitative feedback into measurable insights, enabling brands to stay connected with evolving market perceptions.
          </p>
        </Col>
      </Row>

      {/* Section 1 */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="mb-4 mb-lg-0">
          <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#495057' }}>Understanding Brand Health</h2>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#6c757d', textAlign:"justify" }}>
            Brand health is a comprehensive measure of how your audience perceives your brand and its position in the market.
            Our platform evaluates critical metrics, including brand awareness, consumer sentiment, engagement, and loyalty,
            offering brands a clear picture of their impact and reputation.
          </p>
        </Col>
        <Col lg={6} md={12}>
          <Image src="https://cdn.unravelresearch.com/images/brand-health-tracking-l.jpg" rounded fluid />
        </Col>
      </Row>

      {/* Section 2 */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="order-lg-2 mb-4 mb-lg-0">
          <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#495057' }}>Brand Metrics That Matter</h2>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#6c757d' }}>
            BrandInsights quantifies key brand metrics—such as purchase intent, social engagement, and loyalty—that define your
            brand’s success. Tracking these over time helps brands pinpoint areas for growth and foster stronger audience connections.
          </p>
        </Col>
        <Col lg={6} md={12} className="order-lg-1">
          <Image src="https://cdn.prod.website-files.com/61eff6b3236cf9057b6c1fac/6200ffd5dbff642e9230ce80_5f61f76f83877fbda5943f70_Blog-metric-goal.jpeg" rounded fluid />
        </Col>
      </Row>

      {/* Section 3 */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="mb-4 mb-lg-0">
          <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#495057' }}>Scaling Brand Insights with AI</h2>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#6c757d', textAlign: 'justify' }}>
  AI enables us to analyze extensive qualitative and quantitative data, capturing trends, sentiment, and emerging issues. 
  Using NLP and machine learning, BrandInsights.org, in collaboration with{' '}
  <a href="https://qualz.ai" style={{ color: 'inherit', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
    Qualz.ai
  </a>, turns data into insights, helping brands adapt to market dynamics. By continuously monitoring shifts in consumer behavior, it empowers brands to stay ahead of industry changes. 
  This real-time adaptability allows businesses to make strategic adjustments that foster resilience and growth.
</p>

        </Col>
        <Col lg={6} md={12}>
          <Image src="https://incubator.ucf.edu/wp-content/uploads/2023/07/artificial-intelligence-new-technology-science-futuristic-abstract-human-brain-ai-technology-cpu-central-processor-unit-chipset-big-data-machine-learning-cyber-mind-domination-generative-ai-scaled-1-1500x1000.jpg" rounded fluid />
        </Col>
      </Row>

      
    </Container>
    <ServiceLevels />
      <Footer />

      {/* Other components will go here */}
    </div>
  );
};

export default LandingPage;
