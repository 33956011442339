import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Accordion, Tab, Tabs, Form, Navbar   } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { IoBarChart } from "react-icons/io5";




import Plot from 'react-plotly.js';
import BoxPlotComponent from '../components/visualization/BoxPlotComponent';
import TreeMapChart from '../components/visualization/TreeMapChart';
import "../styles/dashboard.css"
import RenderReportContent from "../components/RenderReportContent";


const brands = [
  { name: 'Nokia', status: '' },
  { name: 'Apple', status: '' },
  { name: 'Samsung', status: '' },
  { name: 'Head&Shoulders', status: '' },
  { name: 'Dove', status: '' },
];

const brandColors = {
  'Nokia': '#8884d8',
  'Apple': '#82ca9d',
  'Samsung': '#ffc658',
  "Head&Shoulders":"lightblue",
  "Dove":"lightgreen"
};

const Dashboard = () => {
  const [selectedBrands, setSelectedBrands] = useState([brands[0].name]);
  const [brandsData, setBrandsData] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState('dashboard');

  const handleBrandChange = (brandName) => {
    setSelectedBrands(prev => {
      if (prev.includes(brandName)) {
        return prev.filter(b => b !== brandName);
      } else {
        return [...prev, brandName];
      }
    });
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    const loadBrandsData = async () => {
      const newBrandsData = {};

  for (const brandName of selectedBrands) {
    try {
      const analysisData = await import(`../brands/${brandName}/analysis.json`);
      const treemapData = await import(`../brands/${brandName}/search_intents.json`);
      const transcriptData = await import(`../brands/${brandName}/transcripts.json`);
      const reportData = await import(`../brands/${brandName}/report.json`);
      const processedData = processAnalysisData(analysisData);

      newBrandsData[brandName] = {
        ...processedData,
        searchIntent: treemapData,
        transcript: Object.values(transcriptData),
        report: reportData.default || [], // Add report data to the brand data
      };
      
    } catch (error) {
      console.error(`Error loading data for ${brandName}:`, error);
    }
  }

  setBrandsData(newBrandsData);
};

    loadBrandsData();
  }, [selectedBrands]);

  const processAnalysisData = (analysisData) => {
    const scoreFrequency = {};
    const perceptionFrequency = {};
    const awarenessFrequency = {};
    const loyaltyFrequency = {};
    const innovationFrequency = {};
    const competitorFrequency = {};
    const socialMediaFrequency = {};
    const reputationFrequency = {};
    const imageFrequency = {};
    const scores = [];

    Object.keys(analysisData).forEach(key => {
      const item = analysisData[key];
      const subKeys = Object.keys(item);
      if (subKeys.length > 0) {
        const subKey = subKeys[0];

        // Extract scores
        if (item[subKey]?.score_option?.promotion_score) {
          const score = item[subKey].score_option.promotion_score.score;
          scores.push(score);
          scoreFrequency[score] = (scoreFrequency[score] || 0) + 1;
        }

        // Extract perception
        if (item[subKey]?.single_choice?.general_brand_perception?.perception) {
          const perception = item[subKey].single_choice.general_brand_perception.perception;
          perceptionFrequency[perception] = (perceptionFrequency[perception] || 0) + 1;
        }

        // Extract awareness
        if (item[subKey]?.single_choice?.brand_awareness?.awareness) {
          const awareness = item[subKey].single_choice.brand_awareness.awareness;
          awarenessFrequency[awareness] = (awarenessFrequency[awareness] || 0) + 1;
        }

        // Extract loyalty
        if (item[subKey]?.single_choice?.brand_loyalty?.loyalty) {
          const loyalty = item[subKey].single_choice.brand_loyalty.loyalty;
          loyaltyFrequency[loyalty] = (loyaltyFrequency[loyalty] || 0) + 1;
        }

        // Extract innovation
        if (item[subKey]?.single_choice?.innovation?.innovation) {
          const innovation = item[subKey].single_choice.innovation.innovation;
          innovationFrequency[innovation] = (innovationFrequency[innovation] || 0) + 1;
        }

        // Extract competitor comparison
        if (item[subKey]?.single_choice?.competitor_comparison?.competitors) {
          const competitor = item[subKey].single_choice.competitor_comparison.competitors;
          competitorFrequency[competitor] = (competitorFrequency[competitor] || 0) + 1;
        }

        // Extract social media preferences
        if (item[subKey]?.multiple_choice?.preferred_social_media?.platform) {
          item[subKey].multiple_choice.preferred_social_media.platform.forEach(platform => {
            socialMediaFrequency[platform] = (socialMediaFrequency[platform] || 0) + 1;
          });
        }

        // Extract reputation
        if (item[subKey]?.multiple_choice?.brand_reputation?.reputation) {
          item[subKey].multiple_choice.brand_reputation.reputation.forEach(reputation => {
            reputationFrequency[reputation] = (reputationFrequency[reputation] || 0) + 1;
          });
        }

        // Extract brand image
        if (item[subKey]?.multiple_choice?.brand_image?.image) {
          item[subKey].multiple_choice.brand_image.image.forEach(image => {
            imageFrequency[image] = (imageFrequency[image] || 0) + 1;
          });
        }
      }
    });

    return {
      scores,
      scoreFrequency,
      perceptionFrequency,
      awarenessFrequency,
      loyaltyFrequency,
      innovationFrequency,
      competitorFrequency,
      socialMediaFrequency,
      reputationFrequency,
      imageFrequency
    };
  };


  const renderComparativeNPSChart = () => {


    // Transform data for all brands
    const allBrandsData = Array.from({ length: 11 }, (_, score) => {
      const dataPoint = {
        score,
      };

      // Add count and percentage for each brand
      selectedBrands.forEach(brand => {
        const brandScores = brandsData[brand]?.scores || [];
        const totalResponses = brandScores.length;
        const count = brandScores.filter(s => s === score).length;
        const percentage = totalResponses > 0 ? ((count / totalResponses) * 100).toFixed(1) : '0.0';

        dataPoint[`${brand}_count`] = count;
        dataPoint[`${brand}_percentage`] = percentage;
      });

      return dataPoint;
    });


    return (
      <Row className="centered-row">
        <Col md={12}>
          <br />
          <h3>NPS Score Distribution</h3>
          <br />
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={allBrandsData}
              margin={{ top: 20, right: 30, left: 20, bottom: 40 }} // Increase bottom margin
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="score"
                label={{
                  value: 'Score',
                  position: 'bottom',
                  offset: 0 // Adjust offset if needed for better spacing
                }}
              />
              <YAxis
                label={{
                  value: 'Number of Responses',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                  dy: 50 // Adjust for vertical centering
                }}
              />
              <Tooltip
                formatter={(value, name) => {
                  if (name.endsWith('_count')) {
                    const brand = name.replace('_count', '');
                    const percentage = allBrandsData.find(d => d[`${brand}_count`] === value)?.[`${brand}_percentage`];
                    return [`${value} responses (${percentage}%)`];
                  }
                  return [value];
                }}
              />
              <Legend wrapperStyle={{ paddingTop: 30, paddingLeft: 40 }} /> {/* Adjust padding to position legend */}
              {selectedBrands.map(brand => (
                <Bar
                  key={brand}
                  dataKey={`${brand}_count`}
                  name={brand}
                  fill={brandColors[brand]}
                  label={(props) => {
                    const { x, y, width, value, index } = props;
                    if (!value) return null; // Don't show label if value is 0
                    const percentage = allBrandsData[index][`${brand}_percentage`];
                    return (
                      <text
                        x={x + width / 2}
                        y={y - 10}
                        fill={brandColors[brand]}
                        textAnchor="middle"
                        fontSize="10"
                      >
                        {`${percentage}%`}
                      </text>
                    );
                  }}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Col>
        <p className="promoter-score">This histogram shows how many people rated the brand from 0 to 10, with the Net Promoter scores on the horizontal axis and the number of people who gave each score on the vertical axis.
</p>
        <Col md={6}>
        <br /><br />
          <h3>NPS Score Box Plot</h3>
          <br />
          <div className='boxPlots'>
            {selectedBrands.map(brand => (
              <div key={brand} className="boxPlotComponent">
                <h4>{brand}</h4>
                <BoxPlotComponent
                  scores={brandsData[brand]?.scores || []}
                  color={brandColors[brand]} // Pass the color dynamically
                />
              </div>
            ))}
          </div>
        </Col>
        <p className="promoter-score">This box plot visualizes the distribution of promotion scores, highlighting the median, quartiles, and any potential outliers among the scores from 0-10 assigned by research participants.</p>

      </Row>
    );
  };
  const generateRandomConsistentColors = (numColors) => {
    const colors = [];
    const hueStep = 360 / numColors; // Divide the hue range into equal parts
  
    for (let i = 0; i < numColors; i++) {
      const baseHue = hueStep * i; // Starting point for this segment
      const hue = Math.floor(baseHue + Math.random() * hueStep); // Randomize within the segment
      const saturation = Math.floor(Math.random() * 40) + 60; // Saturation between 60% and 100%
      const lightness = Math.floor(Math.random() * 30) + 50; // Lightness between 50% and 80%
  
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
  
    return colors;;
  };
  
  
  
  const renderComparativePlot = (metric, title, type = 'pie') => {
    return selectedBrands.map((brand) => {
      const colors = generateRandomConsistentColors(Object.keys(brandsData[brand]?.[metric] || {}).length);
  
      return (
        <Col key={brand} md={12 / selectedBrands.length}>
          <Plot
            data={[{
              type,
              labels: Object.keys(brandsData[brand]?.[metric] || {}),
              values: Object.values(brandsData[brand]?.[metric] || {}),
              marker: {
                colors: colors // Use the generated random colors for each brand pie chart
              },
              textinfo: 'label+percent',
              title: brand
            }]}
            layout={{
              title: `${title} - ${brand}`,
              height: 500, // Increased height
              width: 500,  // Increased width
              showlegend: false
            }}
          />
        </Col>
      );
    });
  };
  
  const renderComparativeBarPlot = (metric, title) => {
    return selectedBrands.map((brand) => {
      const xLabels = Object.keys(brandsData[brand]?.[metric] || {}); // x-labels (categories)
      const yValues = Object.values(brandsData[brand]?.[metric] || {}); // y-values for the specific brand
  
      const colors = generateRandomConsistentColors(xLabels.length); // Generate random colors for each brand bar chart
  
      return (
        <Col key={brand} md={12 / selectedBrands.length}>
          <Plot
            data={xLabels.map((label, index) => ({
              type: 'bar',
              x: [label], // Each bar's x-value
              y: [yValues[index]], // Corresponding y-value
              name: label, // Use the label as the name for the legend
              marker: {
                color: colors[index] // Assign a unique random color for each bar
              }
            }))}
            layout={{
              title: `${title} - ${brand}`,
              height: 500, // Increased height
              width: 500,  // Increased width
              showlegend: true, // Show legend
              legend: {
                orientation: 'v', // Vertical legend
                x: 1.05, // Position of the legend
                y: 1 // Position of the legend
              },
              xaxis: {
                title: '', // No title for x-axis
                showticklabels: false // Hide x-axis labels
              },
              yaxis: { title: 'Count' }
            }}
          />
        </Col>
      );
    });
  };

  return (
    <>

    <div className="d-flex">
      {/* Sidebar */}
      <div className="sidebar">
      <Navbar.Brand href="/">
        <img
          src="/logo.svg" 
          alt="BrandInsights.tech logo"
          style={{ width: 'auto', height: '40px' }}
        />
      </Navbar.Brand>
        <div
          className={`sidebar-icon ${selectedTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => setSelectedTab('dashboard')}
        >
         <IoBarChart size={40} />
          <span className="icon-label">Dashboard</span>
        </div>
        {/* <div
          className={`sidebar-icon ${selectedTab === 'knowledge' ? 'active' : ''}`}
          onClick={() => setSelectedTab('knowledge')}
        >
          <TbChartScatter3D size={40} />
          <span className="icon-label">Knowledge Graphs</span>
        </div> */}
        {/* <div
          className={`sidebar-icon ${selectedTab === 'user' ? 'active' : ''}`}
          onClick={() => setSelectedTab('user')}
        >
          <FaUserCircle size={40} />
          <span className="icon-label">User</span>
        </div> */}
      </div>

      {/* Main Content */}
      <Container fluid className="main-content py-5">
        {selectedTab === 'dashboard' && (
          <Container className="container py-5">
            <Row className="my-4">
              <Col md={12}>
                <h6 className='select-text'>Select Brands to Compare</h6>
                <div className="d-flex flex-wrap gap-3">
                  {brands.map((brand) => (
                    <Form.Check
                      key={brand.name}
                      type="checkbox"
                      id={`brand-${brand.name}`}
                      label={`${brand.name}`}
                      checked={selectedBrands.includes(brand.name)}
                      onChange={() => handleBrandChange(brand.name)}
                      disabled={brand.status === 'In Progress'}
                    />
                  ))}
                </div>
              </Col>
            </Row>

            {selectedBrands.length > 0 && (
            <Tabs defaultActiveKey="metrics" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="metrics" title="Brand Metrics">
              <div style={{ width: '80vw', margin: '0',marginLeft:"240px" }}>

                <Accordion  className="mt-4" defaultActiveKey={['0', '1', '2']} alwaysOpen={true}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Net Promoter Score</Accordion.Header>
                    <Accordion.Body>
                      <p>Net Promoter Score (NPS) measures customer loyalty and satisfaction by asking how likely customers are to recommend a brand, providing key insights into brand health and growth potential.</p>
                      {renderComparativeNPSChart()}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Search Intents</Accordion.Header>
                    <Accordion.Body>
                      <p>Search intent in brand health generally refers to the motivations and interests behind users' searches, often focused on aspects like product information, reviews, technical support, and comparisons, which reflect brand reputation and consumer trust.</p>
                      <Row>
                        <div className='thematicCharts'>
                          {selectedBrands.map(brand => (
                            <div className='threeCharts'>
                              <Col key={brand}>
                                <div className='treeChart'>
                                  <h4 className='themeBrand'>{brand}</h4>
                                  <TreeMapChart jsonData={brandsData[brand]?.searchIntent} />
                                </div>
                              </Col>
                            </div>
                          ))}
                        </div>
                      </Row>
                      <p style={{"textAlign":"center", "fontSize":"1.3rem"}}>This data visualized in a treemap highlights different user-driven interests, with each category representing a specific aspect of the overall search intent for the brand.</p>
                      <br /><br />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Thematic Analysis</Accordion.Header>
                    <Accordion.Body>
                    <Row className="thematic-charts">
                    <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Image</h4>
      <p>Brand image reflects consumers' perceptions and associations with a brand, shaping how it is viewed in terms of quality, reliability, and identity.</p>
      {renderComparativeBarPlot('imageFrequency', 'Image')}
      <h5 className='text-center'>This chart visually represents various attributes associated with a brand's image, highlighting different qualities that contribute to consumers' overall perceptions and associations with the brand. Each color corresponds to a distinct attribute, offering a quick view of how the brand is characterized across multiple dimensions.</h5>
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Perception</h4>
      <p>Brand perception is the collective impression and emotional response consumers have toward a brand, shaped by their experiences, interactions, and associations with its products and messaging.</p>
      {renderComparativePlot('perceptionFrequency', 'Brand Perception')}
      <h5 className='text-center'>This pie chart displays the overall brand perception, categorized into positive, neutral, and negative sentiments. </h5>
    </Row>
  </div>
 
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Awareness</h4>
      <p>Brand awareness refers to the extent to which consumers recognize and recall a brand, indicating their familiarity and overall perception of the brand in the marketplace.</p>
      {renderComparativePlot('awarenessFrequency', 'Brand Awareness')}
      <h5 className='text-center'>This pie chart represents the distribution of consumer commitment to the brand, categorized into High, Medium, and Low segments, reflecting varying degrees of attachment among consumers.</h5>
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Loyalty</h4>
      <p>Brand loyalty reflects consumers' commitment to repurchase or consistently choose a brand over competitors, often driven by positive experiences, trust, and emotional connection.</p>
      {renderComparativeBarPlot('loyaltyFrequency', 'Brand Loyalty')}
      <h5 className='text-center'>This chart illustrates the level of consumer loyalty towards the brand in comparison to its competitors. The values range from High to Low, with "High" indicating a strong commitment and loyalty to the brand. The vertical axis is the number of people who voted each category.</h5>
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Innovation</h4>
      <p>Brand innovation refers to the process of developing new ideas, products, or strategies that enhance a brand's value and relevance in the market, ultimately driving consumer engagement and growth.</p>
      {renderComparativePlot('innovationFrequency', 'Brand Innovation')}
      <h5 className='text-center'>This pie chart displays the distribution of brand innovation levels, with segments ranging from High to Low, illustrating the varying degrees of innovative efforts undertaken by the brand.</h5>
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Competitor Comparison</h4>
<p>Competitor comparison involves evaluating the brand's strengths and weaknesses relative to other brands in the market to identify areas of advantage, disadvantage, or parity.</p>
      {renderComparativeBarPlot('competitorFrequency', 'Competitors')}
      <h5 className='text-center'>This chart compares the brand's performance against competitors, illustrating whether it is ahead, behind, or on par with other brands in the market.</h5>
      
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Preferred Social Media Platforms</h4>
      <p>People engage in discussions about the brand across various platforms, and in the digital realm, its popularity can be assessed by the volume of conversations surrounding it on social media.</p>
      <p></p>
      {renderComparativePlot('socialMediaFrequency', 'Social Media Platforms')}
      <h5 className='text-center'>This pie chart illustrates the preferred social media platforms for customer interaction with the brand, highlighting the distribution of engagement across platforms such as Facebook, Instagram, LinkedIn, and others.

</h5>
    </Row>
  </div>
  <div className="themeCharts">
    <Row className="mb-4">
      <h4 className='text-center'>Brand Reputation</h4>
      <p>Brand reputation refers to the perception and overall assessment of a brand by consumers and stakeholders, shaped by their experiences, interactions, and opinions over time.</p>
      {renderComparativeBarPlot('reputationFrequency', 'Reputation')}
      <h5 className='text-center'>This bar chart displays the dynamic perceptions of brand reputation, with each bar representing attributes such as Reliable, Up-to-date, Trustworthy, and High-quality, while the y-axis indicates the number of respondents who expressed these opinions about the brand.</h5>
    </Row>
  </div>
 
</Row>

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
</div>
              </Tab>
              <Tab eventKey="transcripts" title="Transcripts">
                <Tabs>
                {selectedBrands.map(brand => (
  <Tab key={brand} eventKey={brand} title={brand}>
    <div className="transcripts-container">
      <h2>{brand} Transcripts</h2>
      {brandsData[brand]?.transcript.map((transcriptGroup, index) => {
        // Check if any interview in transcriptGroup has both interviewer and respondent filled
        const hasValidData = Object.values(transcriptGroup).some(
          transcript => transcript.interviewer && transcript.respondent
        );

        // Only render this interview if it has valid data
        if (!hasValidData) return null;

        return (
          <div key={index} className="transcript-container">
            <div onClick={() => toggleExpand(index)} className="transcript-header">
              <strong>{`Interview: ${index + 1}`}</strong>
            </div>
            {expandedIndex === index && (
              <div className="transcript-details">
                {Object.keys(transcriptGroup).map((key, qIndex) => (
                  <div key={qIndex} className="transcript-entry">
                    <p><strong>Interviewer:</strong> {transcriptGroup[key].interviewer}</p>
                    <p><strong>Respondent:</strong> {transcriptGroup[key].respondent}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  </Tab>
))}

                </Tabs>
              </Tab>
              <Tab eventKey="report" title="Report">
      <Container className="container py-5">
      <RenderReportContent selectedBrands={selectedBrands} brandsData={brandsData} />
      </Container>
    </Tab>
            </Tabs>
          )}
          </Container>
        )}

        {selectedTab === 'knowledge' && (
          <Container>
            <h2>Knowledge Graphs</h2>
            <p>Knowledge graphs will be shown here</p>
          </Container>
        )}
      </Container>
    </div>
    </>
  );
};

export default Dashboard;