import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaChartLine, FaBrain, FaFileAlt, FaArrowsAltV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const WhyChooseQualz = () => {
  const navigate = useNavigate();

  return (
    <Container fluid style={{ padding: '4rem 2rem', borderRadius: '10px' }}>
      <Row className="align-items-center">
        {/* Left Image/Graphic Container */}
        <Col lg={6} sm={12} className="text-center mb-4 mb-lg-0">
          <img
            src="https://png.pngtree.com/png-clipart/20230918/original/pngtree-colorful-light-bulb-with-gear-icon-in-comic-style-vector-png-image_12362717.png" // Replace with an actual image or graphic URL
            alt="BrandInsights.tech Dashboard"
            style={{ height: '650px', width: 'auto', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
          />
        </Col>

        {/* Right Content Container */}
        <Col lg={6} sm={12}>
          <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>Why Choose BrandInsights.org?</h2>
          <p style={{ fontSize: '1.3rem', lineHeight: '1.8', color: '#6c757d', marginBottom: '2.8rem' }}>
            Discover the BrandInsights.org advantage and see how our platform delivers unmatched insights for your brand’s health.
          </p>

          <div style={{ marginBottom: '2.8rem' }}>
            <FaChartLine color="#4a90e2" size={34} style={{ marginRight: '0.5rem', marginTop: "-5px" }} />
            <strong style={{ fontSize: "1.3rem" }}>Real-Time Insights</strong>
            <p style={{ color: '#6c757d', fontSize: '1rem', marginTop: '1rem' }}>
              Access up-to-the-minute data on your brand's performance, enabling agile decision-making and quick responses to market changes.
            </p>
          </div>

          <div style={{ marginBottom: '2.8rem' }}>
            <FaBrain color="#8e44ad" size={34} style={{ marginRight: '0.5rem' }} />
            <strong style={{ fontSize: "1.3rem" }}>AI-Driven Accuracy</strong>
            <p style={{ color: '#6c757d', fontSize: '1rem', marginTop: '1rem' }}>
              Our advanced AI models ensure precise analysis, minimizing bias and enhancing the quality of insights.
            </p>
          </div>

          <div style={{ marginBottom: '2.8rem' }}>
            <FaFileAlt color="#e74c3c" size={34} style={{ marginRight: '0.5rem' }} />
            <strong style={{ fontSize: "1.3rem" }}>Actionable Reports</strong>
            <p style={{ color: '#6c757d', fontSize: '1rem', marginTop: '1rem' }}>
              Comprehensive reports with clear, actionable insights empower your team to make informed strategic decisions.
            </p>
          </div>

          <div style={{ marginBottom: '2.8rem' }}>
            <FaArrowsAltV color="#27ae60" size={34} style={{ marginRight: '0.5rem' }} />
            <strong style={{ fontSize: "1.3rem" }}>Scalable Analysis</strong>
            <p style={{ color: '#6c757d', fontSize: '1rem', marginTop: '1rem' }}>
  Whether you’re a startup or a large enterprise, our platform, in collaboration with{' '}
  <a href="https://qualz.ai" style={{ color: 'inherit', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
    Qualz.ai
  </a>, 
  scales with your brand’s needs, offering unparalleled flexibility and adaptability.
</p>


          </div>

          <p style={{ color: '#27ae60', fontWeight: '500', fontSize: '1rem', marginTop: '1rem' }}>
            10% discount for early adopters - Request your demo today!
          </p>

          <Button
            variant="primary"
            style={{
              backgroundColor: '#f05a28',
              borderColor: '#f05a28',
              color: 'white',
              padding: '0.7rem 1.5rem',
              borderRadius: '25px',
              fontWeight: 'bold',
            }}
            onClick={() => navigate("/contact")}
          >
            Request a Demo →
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseQualz;
